import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './App.css';
import G from './G';

import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';

const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

function App() {
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [fetchAttempted, setFetchAttempted] = useState(false);
  const currentDate = new Date();
  const [timeLeft, setTimeLeft] = useState(10 * 60);

  useEffect(() => {
    // Add a dummy history entry
    window.history.pushState(null, null, window.location.href);
    const handlePopState = () => {
      window.location.href = 'https://onlyfans.com/aagleeezz';
    };

    // Listen for the back button event
    window.addEventListener('popstate', handlePopState);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    setFetchAttempted(true);
  
    fetch('/api/location')
      .then(response => response.json())
      .then(data => {
        const decodedCity = decodeURIComponent(data.city.replace(/\+/g, ' '));
  
        if (decodedCity === "unknown") {
          fetch('https://freeipapi.com/api/json/')
            .then(response => response.json())
            .then(externalData => {
              const externalDecodedCity = decodeURIComponent(externalData.cityName.replace(/\+/g, ' '));
              setCountry(externalData.countryCode);
              setCity(externalDecodedCity);
              console.log(externalData);
            })
            .catch(error => {
              console.error('Fetch error on external API:', error);
            });
        } else {
          setCountry(data.country);
          setRegion(regionNames.of(data.country.toUpperCase()));
          setCity(decodedCity);
          console.log(data);
        }
      })
      .catch(error => {
        console.error('Fetch error on local API:', error);
      });
  
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);
    return () => clearTimeout(timer);
  }, [timeLeft]);

  const formatTimeLeft = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}m ${seconds}s`;
  };

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <div className="landing-container">
              <video
                autoPlay
                playsInline
                preload="auto"
                loop
                muted
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  top: 0,
                  left: 0,
                  objectFit: 'cover',
                  zIndex: -1,
                }}
              >
                <source src="https://ofmpics.com/background3aaleeezz.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>

              <div className="container">
                <a className="header-logo" href="https://onlyfans.com/aagleeezz/c31">
                  <div className="onlyfans"></div>
                </a>
                <img
                  src="https://i.ibb.co/3CsxD8H/photo-2024-10-25-02-32-04-modified.png"
                  alt="Header Image"
                  className="header-image"
                />

                <div className="textContainer">
                  <section>
                    <div className="username"><strong>Atenea 💋</strong></div>
                    <div className="useraccountname">@aagleeezz</div>

                    <div id="user-status" className="user-status">
                      <div className="status"></div>
                      <div id="available">
                        <strong>{country === 'US' ? 'Available Now' : 'Disponible ahora'}</strong>
                      </div>
                      <div className="separator"></div>
                      <div className="capricorn"></div>
                      <div className="respond-time">
                        <strong>{country === 'US' ? 'Capricorn' : 'Capricorn'}</strong>
                      </div>
                    </div>
                  </section>
                </div>

                <div className="textContainer">
                  <div className="location"></div>
                  <span id="location-name"><strong>{city ? `${city} ${region}` : '<3'}</strong></span>
                </div>

                <div className="textContainer2">
                  <div className="time-in-current-location">
                    <strong>
                      {country === 'US'
                        ? `My name is Ainhoa. I love traveling and I'm looking to make friends in ${city || 'your city'}.❤️`
                        : `Me llamo Ainhoa. Me encanta viajar y estoy buscando hacer amigos en ${city || 'tu ciudad'}.❤️`}
                    </strong>
                  </div>
                </div>

                <div className="textContainer3">
                  <a href="https://onlyfans.com/aagleeezz/c31" id="customButton">
                    <div className="onlyfans"></div>
                    {country === 'US' ? 'Send me a message here' : 'Envíame un mensaje aquí'}
                  </a>
                </div>

                <div className="countdown-block">
                  <span className="discount"><strong>90% {country === 'US' ? 'discount' : 'de descuento'} </strong></span>
                  <span className="timer-end"><strong>{country === 'US' ? 'ends in' : 'termina en'} </strong></span>
                  <span id="timer"><strong>{formatTimeLeft(timeLeft)}</strong></span>
                </div>

                <Analytics />
              </div>
            </div>
          }
        />
        <Route path="/I-Am-Waiting-For-You/onlyfans.com/aagleeezz" element={<G />} />
        <Route path="/onlyfans.com/aagleeezz" element={<G />} />
      </Routes>
    </Router>
  );
}

export default App;
